import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HotNews from "../sections/blogNews/HotNews";
import SearchBlog from "../sections/blogNews/SearchBlog";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#ffffff",
    paddingTop: "10px",
    paddingBottom: "30px",
    transition: "all .3s ease-in-out",
  },
  paperContent: {
    '@media screen and (min-width: 1025px)': {
      width: "85%",
      margin: "0 auto",
    },
  }
}));

export default function BlogNews({ data }) {
  const classes = useStyles();
  const desktopViewport = useMediaQuery("(min-width:768px)");
  return (
    desktopViewport ?
      <Paper elevation="0" className={classes.paper}>
        <Paper elevation="0" className={classes.paperContent}>
          <HotNews data={data} />
          <SearchBlog data={data.slice(3)} />
        </Paper>
      </Paper> :
      <Paper elevation="0" className={classes.paper}>
        <SearchBlog data={data} />
      </Paper>
  );
}
