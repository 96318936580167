import React from "react"
import { graphql } from "gatsby"
import withRoot from "../components/shell/withRoot"
import BlogNews from "../components/blog/pageContents/BlogNews"
import SEO from "../components/seo"

function BlogPage({ data }) {
  const blog = data.allBlogJson.nodes
  return (
    <React.StrictMode>
      <SEO title="Website học tiếng Nhật Miễn Phí" />
      <BlogNews data={blog} />
    </React.StrictMode>
  )
}

export default withRoot(BlogPage)
export const query = graphql`
  {
    allBlogJson {
      nodes {
        date
        tags
        title
        news
        id
        img {
          alt
          src {
            childImageSharp {
              fluid {
                srcSet
              }
            }
          }
        }
        summary
        content
        author
      }
    }
  }
`
