import React from 'react';
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from 'react';
import ListNewsElement from "./ListNewsElement";
import { Container } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "5px",
    paddingRight: "20px",
    paddingLeft: "20px",
    '@media screen and (min-width: 768px)': {
      paddingRight: "5%",
      paddingLeft: "5%",
    },
  },
  search: {
    display: "flex",
    justifyContent: "flex-end",
  },
  searchLine:{
    width:"250px",
    height:"45px",
    borderBottom:"2px solid #acdefa",
    [theme.breakpoints.down("xs")]:{
      marginTop:"20px",
    },
  },
  searchInput: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#EAEAEA",
    height: "30px",
    border: "1px solid #000",
    width: "100%",
    // marginLeft:"-20%",
    '& input': {
      borderTopStyle: "hidden",
      borderBottomStyle: "hidden",
      borderLeftStyle: "hidden",
      borderRightStyle: "hidden",
      '&:focus':{
        outline: "none"
      }
    }
  },
  searchIcon: {
    color: "#111",
    marginLeft: 30,
  },
  pagination: {
    margin: "5% auto",
    paddingTop:"5%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center"

  },
  showInfo:{
    textAlign:"center",
    fontSize:"25px",
    margin: "70px 0",
    [theme.breakpoints.down("sm")]:{
      margin: "100px 0",
    },
    [theme.breakpoints.down("xs")]:{
      margin: "150px 0",
    }
  },
inputChild:{
  // padding:"10px 10px 10px 10px"
}
}))

export default function SearchBlogs({ data }) {

  const classes = useStyles();
  const filterNews = (data, query) => {
    if (query == "") {
      return data;
    } 
    else {
      return data.filter( (current) => {
        const blogNews = current.news
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/đ/g, 'd')
          .replace(/Đ/g, 'D')
          .toLowerCase();

        return blogNews.includes(
          query.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/đ/g, 'd')
          .replace(/Đ/g, 'D')
          .toLowerCase()
          );
      });
    }
  };
  const [searchQuery, setSearchQuery] = useState('');
  const filteredNews = filterNews(data, searchQuery);
  const blogsPerPage = 4;
  const [page, setPage] = useState(1);
  const LastBlog = page * blogsPerPage;
  const FirstBlog = LastBlog - blogsPerPage;
  const currentBlogs = filteredNews.slice(FirstBlog, LastBlog);
  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <Container className={classes.container}>
         <>
         <div className={classes.search}>
           <div className={classes.searchLine}>
            <div className={classes.searchInput}>
                <input
                  className={classes.inputChild}
                  type="string"
                  placeholder="Tìm kiếm ..."
                  value={searchQuery}
                  onInput={(e) => (
                    setSearchQuery(e.target.value),
                    setPage(1)
                  )
                  }
                />
                <SearchIcon className={classes.searchIcon} />
              </div>
           </div>
         </div>
       </>
      <div>
        {
          currentBlogs.length !== 0
          ?
          currentBlogs.map((blog, index) => (
          <ListNewsElement data={blog} key={index} />
          ))
          :
          <div className ={classes.showInfo}> Không tìm thấy bài viết tương ứng </div> 
        }
      </div>
      <div className={classes.pagination}>
        <Pagination
          count={filteredNews ? Math.ceil(filteredNews.length / 4) : Math.ceil(data.length / 4)}
          color="primary"
          page={page}
          defaultPage={1}
          onChange={handleChange}
          shape='rounded'
        />
      </div>
    </Container>
  );
}

