import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Image from "../../../common/Image";
import CardSummary from "./CardSummary";
import ALink from "../../../common/ALink";

const useStyles = makeStyles({
  root: {
    border: "none",
    overflow: "unset"
  },
  img: {
    width: "100%",
    height: "250px",
    "&:hover": {
      opacity: 0.8
    },
    "@media screen and (min-width: 900px)": {
      height: "300px",
    },
    "@media screen and (min-width: 1024px)": {
      height: "350px",
    },
    "@media screen and (min-width: 1280px)": {
      height: "420px",
    },
  },
});

export default function HotCardLeft(data) {
  const classes = useStyles();
  return (
    <Card className={classes.root} elevation={0} variant="outlined">
      <ALink to={`/blog/${data.data.id}`} className={classes.titleLink}>
        <Image
          className={classes.img}
          {...data.data.img}
        />
      </ALink>
      <CardSummary data={data.data} />
    </Card>
  );
}