import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Image from "../../../common/Image";
import ALink from "../../../common/ALink";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    border: "none",
    overflow: "unset",
    "&:nth-child(1)": {
      marginBottom: "50px"
    }
  },
  img: {
    width: "100%",
    height: "210px",
    objectFit: "cover",
    marginBottom: "20px",
    "&:hover": {
      opacity: 0.8
    },
    '@media screen and (max-width: 1280px)': {
      height: "180px",
    },
    '@media screen and (max-width: 1150px)': {
      height: "160px",
    },
    '@media screen and (max-width: 920px)': {
      height: "150px",
    },
    '@media screen and (max-width: 870px)': {
      height: "120px",
    },
  },
  titleLink: {
    textDecoration: "none"
  },
  newsTitle: {
    color: "#1f65b7",
    fontSize: "18px",
    "&:hover": {
      color: "blue"
    }
  },
});

export default function HotCardRight(data) {
  const classes = useStyles();
  return (
    <Card className={classes.root} elevation={0} variant="outlined">
      <ALink to={`/blog/${data.data.id}`} className={classes.titleLink}>
        <Image
          className={classes.img}
          {...data.data.img}
        />
        <Typography className={classes.newsTitle} variant="h5" component="h5">{data.data.news}</Typography>
      </ALink>
    </Card>
  );
}